import { fromAddress } from "react-geocode";
import {
  getAdTypes,
  getColors,
  getEntityTypes,
  getFeatures,
  getMake,
  getYears,
} from "../services/api";
import advertFormStaticData from "./advertFormStaticData.json";
import { cities } from "./data";
import withoutVin from "./advertFormStaticData.json";

export function isImageOrVideo(url) {
  // Extract the file extension from the URL
  const extension = url?.split?.(".").pop().toLowerCase();

  // Check if the extension corresponds to an image or video format
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const videoExtensions = ["mp4", "avi", "mkv", "mov", "webm"];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
}

export const handleDownload = (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = true;
  a.target = "_blank";
  a.click();
};

export function getYearsFrom1950ToCurrent() {
  const currentYear = new Date().getFullYear();
  const startYear = 1950;

  const years = [];
  let i = 1;
  for (let year = currentYear + 1; year >= startYear; year--, i++) {
    years.push({ id: i, name: { en: year, ar: year, ku: year }, value: year });
  }
  return years;
}

export const fetchAdvertFormData = async () => {
  let response = advertFormStaticData;
  const allApiData = await Promise.all([
    getMake({ isLive: false, limit: 200 }),
    getEntityTypes(),
    getFeatures(),
    getColors(),
    getYears(),
  ]);
  let adData = await getAdTypes();

  response.data["make"] = allApiData[0]?.makes;
  response.data["body"] = allApiData[1]?.data;
  response.data["features"] = allApiData[2]?.data?.features || [];
  response.data["colour"] = allApiData[3]?.data?.colors;
  response.data["yearsList"] = allApiData[4]?.data?.years;
  response.data["adTypes"] = adData.data;
  response.data["cities"] = cities;

  console.log(response);
  return response;
};

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function convertDigitsToEnglish(text) {
  // Define a mapping of Arabic/Kurdish digits to English digits
  const digitMap = {
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };

  const regex = /[٠١٢٣٤٥٦٧٨٩]/g;

  const convertedText = text.replace(
    regex,
    (match) => digitMap[match] || match
  );

  return convertedText;
}

export function camelToNormal(inputString) {
  if (!inputString) return "";
  // Use a regular expression to add spaces before uppercase letters
  const readableString = inputString.replace(/([A-Z])/g, " $1");

  // Convert the first character to uppercase and remove leading space if any
  return (
    readableString.charAt(0).toUpperCase() + readableString.slice(1).trim()
  );
}

export async function translateInTwoLanguages(from, text) {
  console.log(from);
  console.log(text);
  let toLangsArray = [];
  if (from === "ku") from = "ckb";

  if (from == "en") {
    toLangsArray = ["ar", "ckb"];
  } else if (from == "ar") {
    toLangsArray = ["en", "ckb"];
  } else if (from == "ckb") {
    toLangsArray = ["en", "ar"];
  }

  function data() {
    return new Promise((resolve, reject) => {
      resolve(googleTranslate(from, toLangsArray[0], text));
      reject("error");
    });
  }

  function data1() {
    return new Promise((resolve, reject) => {
      resolve(googleTranslate(from, toLangsArray[1], text));
      reject("error");
    });
  }

  let translatedText = await data();
  let translatedText1 = await data1();

  let translatedObj = {
    [toLangsArray[0]]: translatedText.data.translations[0].translatedText,
    [toLangsArray[1]]: translatedText1.data.translations[0].translatedText,
    [from]: text,
  };
  translatedObj = { ...translatedObj, ku: translatedObj?.ckb };

  delete translatedObj["ckb"];

  return translatedObj;
}

export const googleTranslate = async (from, to, text1) => {
  let fromLang = from;
  let toLang = to; // translate to norwegian
  let text = text1;

  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
  url += "&q=" + encodeURI(text);
  url += `&source=${fromLang}`;
  url += `&target=${toLang}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      console.log("There was an error with the translation request: ", error);
    });
};
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatPrice(lang, price, dollor) {
  if (isNaN(price)) price = 0;
  if (parseInt(price) === NaN) return "Any";

  if (dollor && dollor !== "0" && dollor > 0) {
    if (isNaN(dollor)) dollor = 0;
    const exchange = JSON.parse(localStorage.getItem("global"))?.[
      "exchangeRateDollarToIqd"
    ];
    price = exchange * dollor;
    price = parseInt(price) || exchange * dollor;
  }

  const fprice =
    lang === "en"
      ? `${"IQD"} ${numberWithCommas(price)}`
      : `${numberWithCommas(price)} ${"IQD"}`;

  return fprice;
}

export const openAddressOnMap = (address) => {
  if (!address) return;
  fromAddress(address)
    .then(({ results }) => {
      const { lat, lng } = results[0].geometry.location;
      console.log(lat, lng);
      window.open("https://maps.google.com?q=" + lat + "," + lng);
    })
    .catch(console.error);
};

export const getNameFromVal = (obj, val, lang) => {
  return withoutVin?.data?.[obj]?.filter((v) => v.value == val)?.[0]?.name?.[
    lang
  ];
};

export const getFeatureName = (name) => {
  const objBasic = withoutVin.data.basicDetails.filter(
    (v) => v.value === name
  )[0];
  const objSpeci = withoutVin.data.specifications.filter(
    (v) => v.value === name
  )[0];
  const objFeatures = withoutVin.data.features.filter(
    (v) => v.value === name
  )[0];
  return objBasic?.name || objSpeci?.name || objFeatures?.name || null;
};

export function toCamelCase(str) {
  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (/\s+/.test(match)) return ""; // Remove spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export function generatePagination(totalPages, currentPage) {
  const paginationArray = [];
  const firstPagesToShow = 1;
  const lastPagesToShow = 1;
  const pagesAroundCurrent = 1;

  // Add first couple of pages
  for (let i = 1; i <= firstPagesToShow; i++) {
    paginationArray.push(i);
  }
  if (currentPage > 2) {
    paginationArray.push("..");
  }
  // Add pages around the currently selected page
  for (
    let i = currentPage - pagesAroundCurrent;
    i <= currentPage + pagesAroundCurrent;
    i++
  ) {
    if (i > firstPagesToShow && i < totalPages - lastPagesToShow + 1) {
      paginationArray.push(i);
    }
  }
  if (currentPage < totalPages - 2) {
    paginationArray.push("..");
  }
  // Add last couple of pages
  for (let i = totalPages - lastPagesToShow + 1; i <= totalPages; i++) {
    paginationArray.push(i);
  }

  // Remove duplicates and sort the array
  const uniquePaginationArray = [...new Set(paginationArray)].sort(
    (a, b) => a - b
  );
  return paginationArray.includes("..")
    ? paginationArray
    : uniquePaginationArray;
}

export const findUploads = (data) => {
  if (
    data?.uploadsInOrder?.uploads &&
    data?.uploadsInOrder?.uploads?.length > 0
  ) {
    return data?.uploadsInOrder?.uploads;
  } else if (data?.uploads && data?.uploads?.length > 0) {
    return data?.uploads;
  } else {
    return [];
  }
};

export function getVideoCover(file, seekTo = 1) {
  console.log("getting video cover for file: ", file);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file", ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
}

export function blobToFile(blob, fileName) {
  return new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  });
}

export function generateFileFromPath(filePath) {
  return new Promise((resolve, reject) => {
    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const fileName = filePath.split("/").pop(); // Extract filename from the path
        const file = new File([blob], fileName, { type: blob.type });
        resolve(file);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkImageResolution(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
}

export const addWatermark = (compressedBlob, watermarkSrc) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(compressedBlob);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const watermark = new Image();
        watermark.src = watermarkSrc; // Path to watermark image

        watermark.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the compressed image
          ctx.drawImage(img, 0, 0);

          // Draw the watermark (adjust position/size as needed)
          const watermarkWidth = watermark.width;
          const watermarkHeight = watermark.height;
          ctx.globalAlpha = 1; // Adjust opacity of watermark
          ctx.drawImage(
            watermark,
            img.width - watermarkWidth - 10, // Margin from right
            img.height - watermarkHeight - 10, // Margin from bottom
            watermarkWidth,
            watermarkHeight
          );

          // Return the final image as a Blob (avoiding re-encoding at higher quality)
          canvas.toBlob(
            (finalBlob) => {
              resolve(finalBlob);
            },
            "image/jpeg",
            0.7
          ); // Adjust quality to prevent file size increase
        };
      };
    };
  });
};

export const beforeRefresh = () => {
  // localStorage.removeItem("currPageAllAccounts");
  // localStorage.removeItem("tabAllAccounts");
  let keysToRemove = [
    "adTypeData",
    "undefined",

    "currPageAllAccounts",
    "tabAllAccounts",
    "linksAllAccounts",

    "currPageAccountsApprovalQueue",
    "tabAccountsApprovalQueue",

    "currPageUserAdverts",
    "tabUserAdverts",

    "currPageUserTransactions",
    "tabUserTransactions",

    "currPageAllAds",
    "tabUserTypeAllAds",
    "tabAdTypeAllAds",

    "currPageAdsApprovalQueue",
    "tabAdsApprovalQueue",

    "currPageMakes",
    "currPageColors",
    "currPageFeatures",
    "currPageMakeYear"
  ];

  for (let key of keysToRemove) {
    localStorage.removeItem(key);
  }
};
